import classNames from "classnames"
import React, { PropsWithChildren, Children, useEffect, useState } from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

type Props = {
  sectionId: string
  cardWidth: "slim" | "normal" | "extra-slim"
  className?: string
}

SwiperCore.use([Navigation])

const Slider = ({
  sectionId,
  cardWidth,
  children,
}: PropsWithChildren<Props>) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null)

  useEffect(() => {
    if (!swiperInstance) return

    const updateNavVisibility = () => {
      const prevButton = document.querySelector(
        `.swiper-prev-${sectionId}`
      ) as HTMLElement
      const nextButton = document.querySelector(
        `.swiper-next-${sectionId}`
      ) as HTMLElement

      if (prevButton && nextButton) {
        const isPrevDisabled = prevButton.classList.contains(
          "swiper-button-disabled"
        )
        const isNextDisabled = nextButton.classList.contains(
          "swiper-button-disabled"
        )

        prevButton.style.display =
          isPrevDisabled && isNextDisabled ? "none" : "block"
        nextButton.style.display =
          isPrevDisabled && isNextDisabled ? "none" : "block"
      }
    }

    swiperInstance.on("resize", updateNavVisibility)

    updateNavVisibility()

    return () => {
      swiperInstance.off("resize", updateNavVisibility)
    }
  }, [swiperInstance, sectionId])

  return (
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      freeMode
      grabCursor
      containerModifierClass="-mr-gutter-xs sm:-mr-gutter-sm md:-mr-gutter-md lg:-mr-gutter-lg xl:-mr-gutter-xl xl:!pr-0"
      navigation={{
        nextEl: `.swiper-next-${sectionId}`,
        prevEl: `.swiper-prev-${sectionId}`,
      }}
      watchOverflow
      breakpoints={{
        900: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 32,
        },
      }}
      onSwiper={setSwiperInstance}
    >
      {/* TODO Note: the numbers below are calculated based on number of visible cards + the gap between them alongside the gutter to calculate the margin right of last card
       * this should be revisisted with swiper update to see if better way to handle this
       */}
      {Children.map(children, (child, index) => (
        <SwiperSlide
          key={`swiper-slide-${index}`}
          className={classNames(
            "grid h-auto w-[280px]",
            {
              "last:mr-[calc(100vw-5.33vw-280px)] last:sm:mr-[calc(100vw-4.17vw-580px)] md:w-[390px] last:md:mr-[calc(100vw-4.17vw-810px)] lg:w-[495px] last:lg:mr-[calc(100vw-7.22vw-1026px)] last:xl:mr-[calc(100vw-9.76vw-1024px)] last:4k:mr-0":
                cardWidth === "normal",
            },
            {
              "last:mr-[calc(100vw-5.33vw-280px)] last:sm:mr-[calc(100vw-4.17vw-580px)] md:w-[340px] last:md:mr-[calc(100vw-4.17vw-710px)] lg:w-[390px] last:lg:mr-[calc(100vw-7.22vw-1234px)] last:xl:mr-[calc(100vw-9.76vw-1234px)] last:4k:mr-0":
                cardWidth === "slim",
            },
            {
              "last:mr-[calc(100vw-5.33vw-260px)] last:sm:mr-[calc(100vw-4.17vw-540px)] md:w-[270px] last:md:mr-[calc(100vw-4.17vw-570px)] lg:w-[320px] last:lg:mr-[calc(100vw-7.22vw-894px)] last:xl:mr-[calc(100vw-9.76vw-894px)] last:4k:mr-0":
                cardWidth === "extra-slim",
            }
          )}
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
export default Slider
