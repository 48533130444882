import React, { PropsWithChildren } from "react"

import { Color } from "../../../constants/V2/color"
import SliderSectionTitle from "../../elements/V2/SliderSectionTitle"
import LayoutSection, { CTAProps } from "../LayoutComponents/LayoutSection"
import Slider from "../LayoutComponents/Slider"

import { getAccentColor } from "@utils/V2/color"

type Props = PropsWithChildren<
  CTAProps & {
    id?: string
    sectionId: string
    headingSize?: "small" | "medium" | "large"
    headingText?: string
    subheadingText?: string
    overrideHeadingColor?: Color
    cardWidth: "slim" | "normal" | "extra-slim"
    primaryBackgroundColor: Color
    secondaryBackgroundColor: Color
  }
>

const SliderSection = ({
  id,
  sectionId,
  headingSize = "medium",
  headingText,
  subheadingText,
  overrideHeadingColor,
  cardWidth,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  ctaType,
  ctaColor,
  ctaText,
  ctaLink,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  children,
  ...props
}: Props) => {
  return (
    <LayoutSection
      id={id}
      ctaType={ctaType}
      ctaText={ctaText}
      ctaLink={ctaLink}
      ctaColor={ctaColor}
      trackingEvent={trackingEvent}
      trackingEventKey={trackingEventKey}
      trackingEventValue={trackingEventValue}
      primaryBackgroundColor={primaryBackgroundColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      {...props}
    >
      {headingText || subheadingText ? (
        <SliderSectionTitle
          sectionId={sectionId}
          size={headingSize}
          headingText={headingText}
          subheadingText={subheadingText}
          color={overrideHeadingColor || getAccentColor(primaryBackgroundColor)}
        />
      ) : null}

      <Slider sectionId={sectionId} cardWidth={cardWidth}>
        {children}
      </Slider>
    </LayoutSection>
  )
}

export default SliderSection
